/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, meta, title, path, schema, isMultisite }) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						siteUrl
					}
				}
			}
		`
	);

	const metaDescription = description || site.siteMetadata.description;

	return (
		<Helmet
			title={title}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					name: `og:site_name`,
					content: site.siteMetadata.siteName,
				},
				{
					property: `og:title`,
					content: title,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:title`,
					content: title,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
			].concat(meta)}
		>
			{/* Canonical tags for Multisite pages will be handled on the individual templates */}
			{!isMultisite && <link rel="canonical" href={`${site.siteMetadata.siteUrl}/${path}`} />}

			<script key="ld+json" type="application/ld+json">
				{schema}
			</script>
		</Helmet>
	);
}

SEO.defaultProps = {
	meta: [],
	description: ``,
};

SEO.propTypes = {
	description: PropTypes.string,
	path: PropTypes.string.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired,
};

export default SEO;
