import React from 'react';
import { useLocation } from '@reach/router';
import { nanoid } from 'nanoid';
import { Helmet } from 'react-helmet';
import Hero from '../CMSBlocks/Hero';
import SEO from '../SEO';
import Container from '../Container';
import MultiParagraphBlock from '../MultiParagraphBlock';
import ChevronRight from '../../icons/chevron-right.svg';
import NewsCard from '../NewsCard';

const NewsArticlePage = ({
	articleImageUrl,
	title,
	subHeading,
	latestArticles,
	downloadUrl,
	articleCopy,
	pageType,
	slug,
	primarySite,
}) => {
	const location = useLocation();
	return (
		<>
			<SEO
				path={location.pathname}
				title={title}
				description={subHeading}
				meta={[
					{
						name: `og:image`,
						content: articleImageUrl,
					},
				]}
				isMultisite
			/>

			<Helmet>
				<link rel="canonical" href={`${primarySite}${slug}`} />
			</Helmet>

			<Hero bgImg={articleImageUrl} title={title} articleSubHeading={subHeading} fullWidth />

			<section>
				<Container>
					<div className="block grid-cols-6 md:grid">
						<div className="col-span-4 py-5 pr-5">
							<MultiParagraphBlock content={articleCopy} />
						</div>

						<div className="col-span-2 p-5">
							{downloadUrl && (
								<div className="pb-5">
									<h4 className="mb-4 text-brand-300">PRESS RELEASE</h4>
									<a
										href={downloadUrl}
										className="flex items-center justify-between p-3 my-2 ml-auto text-sm font-bold border-2 rounded text-brand-200 hover:text-brand-400 border-secondary-400"
										download
									>
										Download Press Release{' '}
										<ChevronRight className="inline-block text-right rotate-90" />
									</a>
								</div>
							)}
							<div>
								<h4 className="mb-4 uppercase text-brand-300">Latest {pageType}</h4>
								{latestArticles.map((newsItem) => (
									<div className="mb-4 last:mb-0" key={nanoid()}>
										<NewsCard key={nanoid()} newsItem={newsItem} />
									</div>
								))}
							</div>
						</div>
					</div>
				</Container>
			</section>
		</>
	);
};

export default NewsArticlePage;
