import React from 'react';
import { graphql } from 'gatsby';
import DefaultLayout from '../layouts/Default';
import NewsArticlePage from '../components/NewsArticlePage';

const InTheNews = ({ data }) => {
	const { articleCopy, id, articleImage, title, slug, primarySite, subtitle } =
		data.multisiteInTheNews;
	const { nodes: news } = data.allMultisiteInTheNews;
	const otherInTheNews = news.filter((article) => article.id !== id);
	const latestArticles = otherInTheNews
		.sort((a, b) => new Date(b.releaseDate) - new Date(a.releaseDate))
		.slice(0, 3);

	return (
		<NewsArticlePage
			articleImageUrl={articleImage?.url}
			title={title}
			slug={slug}
			primarySite={primarySite}
			subHeading={subtitle}
			latestArticles={latestArticles}
			articleCopy={articleCopy}
			pageType="Articles"
		/>
	);
};

const WrappedInTheNewsPage = (props) => (
	<DefaultLayout fullWidth>
		<InTheNews {...props} />
	</DefaultLayout>
);

export default WrappedInTheNewsPage;

export const query = graphql`
	query inTheNewsQuery($id: String) {
		multisiteInTheNews(slug: { ne: null }, id: { eq: $id }) {
			title
			subtitle
			id
			articleImage {
				url
			}
			articleCopy
			newsType
			slug
			primarySite
		}
		allMultisiteInTheNews {
			nodes {
				title
				subtitle
				isArticleInternal
				externalArticleLink
				releaseDate
				articleImage {
					url
				}
				id
				slug
				newsType
			}
		}
	}
`;
